.Main__App {
    padding: 0 7rem;
}

@media (min-width: 676px) and (max-width: 1175px) {
    .Main__App {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 675px) {
    .Main__App {
        padding: 0 0.2rem;
    }
}