.portfolio__bio {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 5rem 0 3rem 0;
}

.portfolio__bio > h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #892CDC;
}

.portfolio__bio >p {
    color: var(--text);
    font-size: 1rem;
    text-align: center;
}


.portfolio__search {
    display: flex;
    justify-content: right;
    padding: 2rem 0;
}

.portfolio__search > input{
    border-radius: 0.5em;
    background-color: transparent;
    padding: 0.5rem 1rem;
    color: #892CDC;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #892CDC;
}

/* card css */
.portfolio-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.portfolio-item {
    border: 1px solid #892CDC;
    border-radius: 7px;
    padding: 15px;
    width: 310px;
    box-shadow: var(--CardShadow);
}

.portfolio-item > img {
    max-width: 100%;
    height: 150px;
    border-radius: 7px;
}

.portfolio-item > h3 {
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    color: #892CDC;
    padding: 7px 0;
}

.portfolio-item > p {
    font-size: 0.8rem;
    text-align: center;
    color: var(--text);
    padding: 7px 0;
}

.portfolio-item > a {
    text-decoration: none;
    color: #892CDC;
    background-color: #8a2cdc71;
    width: 100%;
    display: block;
    text-align: center;
    height: 40px;
    border-radius: 7px;
    align-content: center;
    font-weight: 500;
    transition: all 0.8ms ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #892CDC;
        color: white;
    }
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin: 2rem 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    text-decoration: none;
    color: #892CDC;
    cursor: pointer;
}

.pagination li a:hover {
    text-decoration: underline;
}

.pagination .active a {
    font-weight: bold;
    color: var(--text);
}

