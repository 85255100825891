.Contact__btn {
    display: inline-flex;
    padding: 7px 21px;
    border: 1px solid #8E05C2;
    border-radius: 4px;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    cursor: pointer;
    text-transform: capitalize;
    font-family: var(--primary-text);
    font-weight: 500;
    align-items: center;
    color: #8E05C2;
    z-index: 1;
  }
  
  .Contact__btn:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .Contact__btn:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #8E05C2;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }
  
  .Contact__btn:hover {
    color: #ffffff;
    border: 1px solid #8E05C2;
  }
  
  .Contact__btn:hover:before {
    top: -35%;
    background-color: #8E05C2;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
  
  .Contact__btn:hover:after {
    top: -45%;
    background-color: #8E05C2;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }