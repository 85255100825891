.experience > h2{
    font-size: 3rem;
    font-weight: 400;
    color: var(--text);
    text-align: center;
}

.experience > hr {
    width: 100%;
    height: 2px;
    border: none;
    background-color: radial-gradient(circle, rgba(137,44,220,1) 0%, rgba(188,111,241,1) 50%);
    background: rgb(137,44,220);
    margin-bottom: 1rem;
}

.experience__card {
    background-color: var(--cardBg);
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: var(--CardShadow);
    border: 2px solid #892CDC;
}

.experience__card > .experience__date {
    font-size: 1.5rem;
    font-weight: 800;
    color: #892CDC;
    margin-bottom: 10px;
}

.experience__card > .experience__title {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text);
    margin-bottom: 10px;
}

.experience__card > .experience__company {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #999999;
    margin-bottom: 10px;
}

.experience__card > .experience__description {
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}

.experience__card > .experience__exerptise {
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.experience__card > .experience__exerptise > li {
    list-style: disc;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-left: 1.5rem;
}