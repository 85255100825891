/* File: src/style/_typewriter.css */

.typewriter {
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid var(--text);
    display: inline-block;
    animation: blink 0.75s step-end infinite;
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--text);
    font-family: 'Poppins', 'Nunito';
  }
  
  @keyframes blink {
    from, to { border-color: transparent; }
    50% { border-color: var(--text); }
  }
  