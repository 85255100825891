.github__banner {
    background-image: url(https://anuragdevnath.github.io/Portfolio/images/bg_1.jpg);
    padding: 7em 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    justify-content: center;
    display: flex;
    margin: 2rem 0;
}

.github__banner > div {
    width: 300px;
    height: auto;
    background: transparent !important;
}

.github__banner > div > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent !important;
    color: white;
    font-size: 1.7rem;
    font-weight: 700;
}