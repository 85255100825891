.hero__component {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 80vh;
    justify-content: space-between;
}

.hero__component > .left__side {
    /* height: 60vh; */
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.hero__component > .right__side{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.hero__component > .right__side > .profile__div__shape {
    height: 350px;
    width: 350px;
    border-radius: 50%;
    border: 2px solid #892CDC;
    box-shadow: 5px 5px 100px 5px #892CDC;
    overflow: hidden;
}

.hero__component > .right__side > .profile__div__shape > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

@media screen and (max-width: 600px) {

    .hero__component {
        flex-direction: column;
        height: 100vh;
    }

    .hero__component > .left__side {
        /* height: 60vh; */
        align-items: center !important;
        width: 100%;
        /* padding: 2rem 0; */
    }

    .hero__component > .right__side {
        width: 100%;
    }

    .hero__component > .left__side > .bio__div {
        text-align: center;
        padding: 2rem 0 0 0;
    }
}

.left__side > .bio__div {
    text-align: left;
}

.bio__div > h6 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #892CDC;
    letter-spacing: 3px;
    font-family: 'Poppins', 'Nunito';
}

.bio__div > h2 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.3;
    margin: 0.5rem 0;
}

.typing__animation > h3 {
    color: var(--text);
    font-weight: 700;
    font-size: 1.8rem;
}

.hero__btn {
    margin: 1rem 0;
    gap: 20px;
    display: flex;
}

.hero__btn > a > .github__btn {
    border-radius: 0.5em;
    background-color: #892CDC;
    padding: 0.5rem 1rem;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    border: none;

    &:hover,
    &:active,
    &:focus {
        background-color: #52057B;
    }
}

.hero__btn > a > .linkedin__btn {
    border-radius: 0.5em;
    background-color: #ffffff;
    padding: 0.5rem 1rem;
    color: black;
    font-size: 1rem;
    font-weight: 400;
    border: none;

    &:hover,
    &:active,
    &:focus {
        background-color: #131313;
        color: #ffffff;
    }
}