.Contact__Icon__Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all 0.5ms ease-in-out;
}

.Contact__Icon__Div> a:hover {
    color: #892CDC !important;
}

.Contact__Icon__Div >  h2 {
    padding: 0.5rem;
    width: 100px;
    height: 100px;
    background-color: var(--background);
    color: #892CDC !important;
    border: 1px solid var(--navborder);
    border-radius: 50%;
    box-shadow: var(--CardShadow);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Contact__Icon__Div > h2 > .icon {
    font-size: 2rem;
    color: #892CDC !important;
}

.Contact__Icon__Div> a > p {
    margin-top: 1.5rem;
    color: var(--text);
    font-size: 1rem;
}

.Contact__Icon__Div > p {
    margin-top: 1.5rem;
    color: var(--text);
    font-size: 1rem;
}

.Contact__Icon__Div > a> p {
    transition: all 0.5s ease-in;
    &:hover {
        color: #892CDC !important;
    }
}