header {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it is above other content */
    width: 100%;
}

/* You can add additional styling to ensure the header looks good */
.desktop__header, .mobile__header {
    background-color: var(--background);
    color: var(--text);
    border-bottom: 1px solid gray;
}

.desktop__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
    padding: 1.5rem 0;
}

.desktop__header>.logo {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
}

.logo>h1 {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--text);
    font-family: 'Poppins', 'Nunito';
}

.desktop__header>.menu__item {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: right;
    gap: 15px;
}

.menu__item>.items {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: right;
    gap: 15px;
}

.menu__item>.items>li {
    list-style: none;
}

.items>li>a {
    text-decoration: none;
    color: var(--text);
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', 'Nunito';
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        color: #892CDC;
        letter-spacing: 2px;
        clear: both;
    }
}

.menu__item>.Two_btns {
    gap: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: right;
}

.Two_btns>.contact__btn {
    text-decoration: none;
    color: var(--text);
}

.contact__btn>button {
    color: white;
    border: 1px solid #52057B;
    background-color: #892CDC;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito', 'Poppins';
    padding: 0.5em 1.2em;
    border-radius: 0.5em;
}

.contact__btn>button:hover,
.contact__btn>button:focus,
.contact__btn>button:active {
    color: white;
    border: 1px solid #892CDC;
    background-color: #52057B;
}

/* mobile navbar  */
.mobile__header {
    display: none;
}

.mobile__nav {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    align-items: center;
}

.mobile__logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.mobile__logo> h1 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito', 'Poppins';
    color: var(--text);
}

.mobile__item {
    width: 350px;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    z-index: 999;
    background-color: black;
    right: 0;
    top: 0;
    background: var(--navBg);
    border-radius: 16px;
    box-shadow: var(--navShadow);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: var(--navborder);
    transition: all 0.4s ease-in-out;
    transform: translateX(100%);
}

.mobile__icon {
    color: var(--text);
    background-color: transparent !important;
    font-size: 1.5rem;
    cursor: pointer;
}

.mobile__item > nav {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background-color: transparent !important;
}

.mobile__item > nav > a{
    padding: 0.3rem 0;
    text-align: center;
    text-decoration: none;
    color: var(--text);
    background-color: transparent !important;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', 'Nunito';
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        color: #892CDC;
        letter-spacing: 2px;
        clear: both;
    }
}

@media screen and (max-width: 1065px) {
    .desktop__header {
        display: none;
    }

    .mobile__header {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        align-items: center;
        padding: 1.5rem 5rem;
    }
    /* .mobile__icon {
        margin-right: 10px;
} */
}

@media screen and (max-width: 675px) {
    .mobile__header {
        padding: 1.5rem 0rem;
    }
}