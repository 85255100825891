@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 0.2s, color 0.3s;
  background-color: var(--background);
  color: var(--text);
  font-family: 'Poppins', 'Nunito' !important;
}

/* src/CustomScrollbar.css */

/* WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #892CDC;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #892CDC;
  border-radius: 10px;
  border: 3px solid #f1f1f1; /* Padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox */
body, html {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #892CDC; /* thumb and track color */
}

/* Optional: Custom scrollbar for specific elements */
.custom-scrollbar {
  overflow-y: scroll; /* Enable scrolling if needed */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #892CDC;
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #892CDC;
}


body, html {
  font-family: 'Poppins', 'Nunito' !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.theme-toggle {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid var(--toggleBorder);
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}