.profile__div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .profile_img {
        display: none;
    }
}

.profile_img {
    width: 150px;
    height: 150px;
    margin-right: 2rem;
    border: 7px solid #892CDC;
    border-radius: 50%;
    overflow: hidden;
}

.profile_img > img {
    width: 100%;
    object-fit: cover;
}

.profile_info {
    line-height: 2rem;
}

.profile_info > p {
    color: #999999;
}

.skill-mf > p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--text);
}

.skill-mf .progress {
    /* background-color: #cde1f8; */
    margin: 0.5rem 0 1.2rem;
    border-radius: 0;
    height: 0.7rem;
}

.skill-mf .progress .progress-bar {
    height: 0.7rem;
    background-color: #52057B;
}

.progress-bar {
    background: #52057B;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 12px;
    line-height: 1.2;
    color: #000000;
    font-weight: 600;
    position: relative;
    overflow: visible;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
}

.progress-bar:after {
    position: absolute;
    top: -2px;
    right: 0;
    width: 34px;
    height: 34px;
    content: '';
    background: #52057B;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    opacity: 0;
}

.aboutme__content > h2{
    color: var(--text);
    font-weight: 700;
    font-size: 2.5rem;
    text-align: left;
}

.aboutme__content > hr {
        width: 100px;
        position: relative;
        height: 4px;
        background-color: #892CDC;
        margin-bottom: 1rem;
        border: none;
}

.aboutme__content > p {
    color: #999999;
}