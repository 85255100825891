.resume__detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.resume__detail > hr {
    width: 100px;
    position: relative;
    height: 4px;
    background-color: #892CDC;
    margin-bottom: 1rem;
    border: none;
}

.resume__detail > h2 {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    color: var(--text);
}

.resume__detail > p {
    text-align: center;
    color: #999999;
    max-width: 600px;
}